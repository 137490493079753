@import "../vars.scss";

.root {
  text-align: center;
  line-height: 1.5;
}

.biglogo {
  width: 80%;
  max-width: 900px;
  margin: 0 auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @media (max-height: 1200px) {
    max-width: 900px;
  }
}

.host {
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 1.2;
}

.buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 40px;

  > * {
    width: 300px;
    margin-bottom: 0;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    gap: 0;
    margin: 0 20px;

    > * {
      width: initial;
    }

    > *:first-child {
      margin-bottom: 20px;
    }
  }
}

.floaterWrapper {
  width: 100%;
  height: 100%;
}

.floaterOuterWrapper {
  pointer-events: none;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.vert {
  height: 100vh;
  // padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  min-height: 780px;

  > div {
    padding-top: 40px;
  }
}

.topText {
  font-size: 30px;
  text-transform: uppercase;
}

.tickerTitle {
  margin-top: 30px;
  text-transform: uppercase;
}

.ticker {
  overflow: hidden;
  background-color: #d6bfbb;
  white-space: nowrap;
  text-transform: uppercase;
  position: relative;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.tickerNames {
  position: absolute;
  display: block;
  color: $navy;
  line-height: 50px;
  font-size: 24px;
}

$speed: 50s;

.ticker1 {
  animation: marquee1 $speed linear infinite;
}

.ticker2 {
  animation: marquee2 $speed linear infinite;
  // color: red;
  transform: translateX(100%);
}

@keyframes marquee2 {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee1 {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-100%);
  }
  50.0000000001% {
    transform: translateX(100%);
  }
}
