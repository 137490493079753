.root {
  display: flex;
  text-align: left;
  padding: 0 40px 40px 40px;
  background-color: black;
  justify-content: space-between;
  @media (max-width: 650px) {
    display: block;
  }
}

.logoLink {
  background-image: url("./img/anthem/footer-logo.svg");
  color: transparent;
  width: 86px;
  height: 76px;
  display: inline-block;
  margin-top: 35px;
}

.social {
  a {
    display: inline-block;
    width: 23px;
    height: 23px;
    margin-top: 40px;
    margin-right: 15px;
  }
}

.links {
  a {
    color: white;
    text-transform: uppercase;
    display: inline-block;
    margin-right: 15px;
    margin-top: 25px;
  }
}

.copy {
  margin-top: 1rem;
  font-size: 12px;
}

.center {
  text-align: center;
}

.right {
  padding-top: 35px;
  font-size: 12px;
}
