.root {
  padding-top: calc(7vh + 20px);
  min-height: calc(100vh - 7vh);
  display: flex;
  justify-content: space-around;
}

.videoHolder {
  width: 660px;
  max-width: 100%;
}

.buttonGroup {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  //   height: 600px;

  gap: 20px;
  margin: 0 20px;

  @media (max-width: 900px) {
    margin: 20px 0;
    flex-direction: column;
  }
}

.inner {
  max-width: initial;
}
