@import "../vars.scss";

.greeting {
  text-transform: uppercase;
  font-size: 59px;
  margin-top: 200px;
  @media (max-width: 680px) {
    margin-top: 100px;
    font-size: 45px;
  }
}

.intro {
  font-size: 24px;
}

.project {
}

.pieceTitle {
  font-size: 32px;
  text-transform: uppercase;
}

.pieceAward {
  margin-top: 20px;
}

.pieceAwardTitle {
  font-weight: bold;
}

.upload {
  cursor: pointer;
  background-color: #fff;
  color: black;
  padding: 20px 40px;

  &:hover {
    background-color: black;
    color: $salmon;
  }
}
