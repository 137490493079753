.root {
  background-color: #d6bfbb;
  text-align: center;
  color: black;
  padding: 30px;
}

.logo {
  display: inline-block;
  vertical-align: middle;
  max-width: 100px;
}

.heading {
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
}
